import RailTosService from "../service/tos/RailTosService";
import TosEditFormVuetify from "../components/anagrafiche/TosEditFormVuetify";
import TosViewItemVuetify from "../components/anagrafiche/TosViewItemVuetify";
import ToastMixin from "./ToastMixin";
import FormatsMixins from "./FormatsMixins";
import i18n from "../i18n";
import moment from "moment";

export default {
  data() {
    return {
      items: [],
      item: {},
      selectedItem: {},
      loading: false,
      totalRecords: 0,
      columns: [],
      columnOptions: [],
      filters: {},
      dialogInfoVisible: false,
      dialogInfoMessage: "",
      infoItem: {},
      editResponse: null,
      dialogCustomizeVisible: false,
      listMode: true,
      tableOptions: null,
      tableReady: false,
      confirmMethod: null,
      confirmData: null,
      confirmMessage: null,
      confirmDialogVisible: null,
      readonly: false
    };
  },
  props: {
    serviceName: {
      type: String,
      default: () => {
        return "rail";
      },
    },
    entityName: {
      type: String,
      required: true,
    },
    pageTitle: {
      type: String,
    },
    headers: {
      type: Array,
    },
    defaultFilters: {
      default: () => {
        return {};
      },
    },
    defaulOptions: {
      default: () => {
        return {};
      },
    },
    /**
     * Di default, appena montato, viene eseguita ricerca. Mettere a false se si vuole evitare,
     *  per esempio per impostare altri filtri.
     */
    filterOnMounted: {
      type: Boolean,
      default: true,
    },
    /**
     * Funzione validazione. Se non valorizzata, viene usata, se esiste, validate(item) del componente padre.
     */
    validateFunction: {
      type: Function,
    },
    /**
     * Funzione preparazione item, prima di new/edit (utile es per valorizzare attributi default)
     *  Se non valorizzata, viene usata, se esiste, prepareItemF(item) del componente padre.
     */
    prepareItemFunction: {
      type: Function,
    },
    /**
     * Funzione preparazione filtri. Se non valorizzata, viene usata, se esiste, initFilters(item) del componente padre.
     */
    initFiltersFunction: {
      type: Function,
    },
    excludeButtons: {
      default: () => {
        return {};
      },
    },
    /**
     * ordinamento default
     */
    defaultSort: {
      type: Object,
      default: () => {
        return { field: null, desc: null };
      },
    },
  },
  service: null,
  filters: {},
  computed: {
    pageTitleLabel() {
      if (this.pageTitle) return this.pageTitle;
      return i18n.t("Gestione").toUpperCase() + " " + i18n.t(this.entityName).toUpperCase(); // this.$options.filters.humanize(this.entityName)
    },
    editLabel() {
      return i18n.t("Modifica").toUpperCase() + " " + i18n.t(this.entityName).toUpperCase(); // this.$options.filters.humanize(this.entityName);
    },
    createLabel() {
      return i18n.t("Creazione").toUpperCase() + " " + i18n.t(this.entityName).toUpperCase(); // this.$options.filters.humanize(this.entityName);
    },
    errors() {
      if (this.editResponse) return this.editResponse.error;
      return [];
    },
    validateFunctionComputed() {
      return this.validateFunction || this.$parent.validate;
    },
    prepareItemFunctionComputed() {
      return this.prepareItemFunction || this.$parent.prepareItem;
    },
    initFiltersFunctionComputed() {
      return this.initFiltersFunction || this.$parent.initFilters;
    },
  },
  mixins: [ToastMixin, FormatsMixins],
  created() {
    this.service = new RailTosService(this.entityName, this.serviceName);
    this.$parent.$on("filterTable", filtersData => {
      this.filters = { ...this.defaultFilters, ...filtersData };
      this.onRefresh();
    });
    this.$parent.$on("refreshTable", () => {
      this.onRefresh();
    });
    this.$parent.$on("enterOnSelect", ref => {
      const searchEl = this.$parent.$refs[ref];
      if (searchEl) {
        searchEl.blur();
      }
      this.onRefresh();
    });
    this.$parent.$on("tableOpenItem", id => {
      this.onOpenItem(id);
    });
    this.$parent.$on("tableAddNewItem", () => {
      this.addNewItem();
    });
    this.$parent.$on("confirm", (confirmMessage, confirmMethod, confirmData) => {
      this.doConfirm(confirmMessage, confirmMethod, confirmData);
    });
    this.$parent.$on("cleanTable", () => {
      this.onCleanFilters();
    });
  },
  mounted() {
    this.columnOptions = this.headers;
    this.columns = this.headers;
    if (this.columnOptions == null) {
      this.columnOptions = [];
      this.service.getMetadata().then(data => {
        console.log("getMetadata", data);
        for (var i = 0; i < data.columns.length; i++) {
          var d = data.columns[i];
          this.columnOptions.push({
            text: d.header,
            value: d.name,
            link: d.link == "true",
            columnType: d.type,
          });
        }
        const columns = [],
          columnOptions = this.columnOptions;
        data.list.split(",").forEach(function(item) {
          columnOptions.forEach(function(c) {
            if (c.value == item) columns.push(c);
          });
        });
        this.columns = columns;
      });
    }

    this.doInitFilters(this.filterOnMounted);
    const loggedUser = this.$store.getters['authentication/loggedUser'];
    this.readonly = loggedUser?.roles?.includes("EXT_TOS_USER");
  },
  watch: {
    defaultFilters: {
      handler() {
        this.doInitFilters(true);
      },
      deep: true,
    },
    defaulOptions: {
      handler() {
        this.tableOptions = this.defaulOptions;
      },
      deep: true,
    },
    tableOptions: {
      handler() {
        if (this.tableReady) this.doLoadPage(false);
      },
      deep: true,
    },
  },
  methods: {
    onRefresh() {
      Object.keys(this.filters)
        .forEach((k) => this.filters[k] == null && delete this.filters[k]);
      this.doLoadPage(true);
      const data = { key: this.$router.history.current.path, filters: this.filters };
      if("true" == localStorage.getItem('tos-office-keep-filters')){
        this.$store.commit("configuration/setFilters", data);
      }
    },
    onCleanFilters() {
      this.filters = { ...this.defaultFilters };
      delete this.filters.codiceCodiceEsternoNome;
      delete this.filters.codiceUTI;
      delete this.filters.eta___GT;
      delete this.filters.eta___LT;
      delete this.filters.targaCarro;
      delete this.filters.nodoPartenzaId;

      if (this.initFiltersFunctionComputed) this.initFiltersFunctionComputed(this.filters);
      this.onRefresh();
    },
    onCustomize() {
      this.dialogCustomizeVisible = true;
    },
    async doLoadPage(doCount) {
      // console.log("------------- tableOptions -------------- ", doCount, this.tableOptions);
      if (this.tableOptions == null) return;
      this.loading = true;
      if (doCount) this.tableOptions.page = 1;
      const itemsPerPage = this.tableOptions.itemsPerPage > 0 ? this.tableOptions.itemsPerPage : 9999;
      var sortOrder0 = this.tableOptions.sortDesc && this.tableOptions.sortDesc !== null && this.tableOptions.sortDesc.length>0 ? this.tableOptions.sortDesc[0] : null;
      const sortOrder = (sortOrder0 === null ? this.defaultSort.desc : sortOrder0) ? "1" : "0";

      try {
        const resp = await this.service.list(
          (this.tableOptions.page - 1) * itemsPerPage,
          itemsPerPage,
          this.tableOptions.sortBy[0] || this.defaultSort.field,
          sortOrder,
          this.filters,
          doCount
        );
        this.items = resp.data;
        if (doCount) this.totalRecords = resp.recordsFiltered;
      } catch (err) {
        this.showError("Si è verificato un errore: " + err.message);
      }
      this.loading = false;
    },
    addNewItem() {
      this.item = {};
      if (this.editComponentPath) {
        this.$router.push({ path: this.editComponentPath });
        return;
      }
      this.listMode = false;
      this.editResponse = null;
      if (this.prepareItemFunctionComputed) this.prepareItemFunctionComputed(this.item);
    },
    async deleteItem(id) {
      console.log("deleteItem id:" + id);
      this.loading = true;
      try {
        await this.service.delete(id);
        let index = this.findIndexById(id);
        this.items = this.items.filter((val, i) => i !== index);
        this.showSuccess("Record cancellato");
      } catch (e) {
        console.log("Errore delete record", e);
        this.showError("Errore: " + e.detailMessage);
      }
      this.loading = false;
    },
    doConfirm(confirmMessage, confirmMethod, confirmData) {
      this.confirmMessage = confirmMessage;
      this.confirmData = confirmData;
      this.confirmMethod = confirmMethod;
      this.confirmDialogVisible = true;
    },
    onConfirm() {
      this.confirmDialogVisible = false;
      let method = this[this.confirmMethod];
      if (method == null) {
        console.warn("Confirm method null:", this.confirmMethod);
        return;
      }
      method(this.confirmData);
    },
    async saveItem() {
      const valid = this.$refs.editForm.validate();
      if (!valid) {
        this.editResponse = {
          message: "Errori di validazione presenti",
          errors: [],
        };
        window.scrollTo(0, 0);
        return;
      }
      if (this.validateFunctionComputed) {
        const jsErrors = {};
        this.validateFunctionComputed(this.item, {
          add: function(key, value) {
            jsErrors[key] = jsErrors[key] ? jsErrors[key] + "; " + value : value;
          },
        });
        if (jsErrors != null && Object.keys(jsErrors).length > 0) {
          this.editResponse = {
            message: "Errori di validazione presenti",
            errors: jsErrors,
          };
          window.scrollTo(0, 0);
          return;
        }
      }
      const item = this.item;
      this.editResponse = null;
      try {
        ////const data = await (item.id ?  this.service.put(item,  "/"+item.id) : this.service.post(item,""));
        const data = await this.service.post(item, "/edit");
        this.listMode = true;
        this.item = data;
        let index = this.findIndexById(this.item.id);
        let items = [...this.items];
        if (index === -1) {
          items.push(this.item);
          this.totalRecords += 1;
        } else {
          items[index] = this.item;
        }
        this.items = items;
        this.item = null;
        this.selectedItem = null;
        this.showSuccess("Salvataggio eseguito con successo");
        this.loading = false;
      } catch (e) {
        console.log("Risposta saveItem", e);
        window.scrollTo(0, 0);
        this.editResponse = e.data;
      }
    },
    async onOpenItem(id) {
      this.loading = true;
      this.editResponse = null;
      let index = this.findIndexById(id);
      this.item = { ...this.items[index] };
      this.item = await this.service.get(id);
      this.listMode = false;
      this.loading = false;
      if (this.prepareItemFunctionComputed) this.prepareItemFunctionComputed(this.item);
    },
    onRowSelect(event) {
      this.item = { ...event.data };
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].id === id) {
          index = i;
          break;
        }
      }

      return index;
    },
    onRowUnselect() {},
    showInfo(obj) {
      this.dialogInfoVisible = true;
      this.infoItem = obj;
      this.dialogInfoMessage = "TODO info # " + obj.label + " type: " + obj.type;
    },
    colLabelFunct: function(col, data) {
      if (col.label) {
        return col.label;
      }
      if (col.labelFunct) return col.labelFunct(data);
    },
    onCustomClick: function(col, data) {
      this.$emit(col.link, data);
    },
    doInitFilters(loadPage) {
      const storeFilters = this.$store.getters["configuration/listFilters"](this.$router.history.current.path) || {};
      this.filters = { ...storeFilters, ...this.defaultFilters };
      if (this.initFiltersFunctionComputed) this.initFiltersFunctionComputed(this.filters);
      if (loadPage) {
        this.tableReady = true;
        this.doLoadPage(true);
      }
    },
    async exportCSV() {
      var csvContent = "data:text/csv;charset=utf-8,";
      const respsd = await this.service.list(0, 9999, this.tableOptions.sortBy[0], this.tableOptions.sortDesc[0] ? "1" : "0", this.filters, false);
      const allFilteredItems = respsd.data;
      this.columns.forEach(col => {
        if (col.value !== "action" && col.value !== "actions") csvContent += col.text + ";";
      });
      allFilteredItems.forEach(item => {
        csvContent += "\n";
        this.columns.forEach(col => {
          if (col.value !== "action" && col.value !== "actions") {
            var value = this.evaluateExpr(item, col);
            if (value === null) {
              csvContent += "" + ";";
            } else {
              if (this.isNumeric(value)) {
                csvContent += '="' + value + '"' + ";";
              } else {
                csvContent += '"' + value + '"' + ";";
              }
            }
          }
        });
      });

      csvContent.replace(/(^\[)|(\]$)/gm, "");
      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
    },
    evaluateExpr(item, col) {
      if (item == null) return null;
      let value = item;
      const t = col.value.split(".");
      t.forEach(f => {
        if (value == null) return null;
        switch (f) {
          case "hinVoyageCode":
            if (value.handlingsIn && !value.handlingsIn.isEmpty) {
              let voyageIn = "";
              value.handlingsIn.forEach((f) => {
                voyageIn = voyageIn + f.voyageCode + " ";
              });
              value = voyageIn;
            } else {
              value = null;
            }
            break;
          case "houtVoyageCode":
            if (value.handlingsOut && !value.handlingsOut.isEmpty) {
              let voyageOut = "";
              value.handlingsOut.forEach((f) => {
                voyageOut = voyageOut + f.voyageCode + " ";
              });
              value = voyageOut;
            } else {
              value = null;
            }
            break;
          case "houtTransportType":
            if (value.handlingsOut && !value.handlingsOut.isEmpty) {
              let transportTypeOut = "";
              value.handlingsOut.forEach((f) => {
                transportTypeOut = transportTypeOut + f.transportType + " ";
              });
              value = transportTypeOut;
            } else {
              value = null;
            }
            break;
          case "hinOrderCode":
            if (!value.handlingsIn || value.handlingsIn.isEmpty) return null;
            return value.handlingsIn.find((h) => "TRU".equals(t.transportType));
            break;
          case "houtOrderCode":
            if (!value.handlingsOut || value.handlingsOut.isEmpty) return null;
            return value.handlingsOut.find((h) => "TRU".equals(t.transportType));
            break;
          case "hinTransportType":
            if (value.handlingsIn && !value.handlingsIn.isEmpty) {
              let transportTypeIn = "";
              value.handlingsIn.forEach((f) => {
                transportTypeIn = transportTypeIn + f.transportType + " ";
              });
              value = transportTypeIn;
            } else {
              value = null;
            }
            break;
          case "dataIn":
            if (value.handlingsIn && !value.handlingsIn.isEmpty) {
              let dataIn = "";
              value.handlingsIn.forEach((f) => {
                if (f.execDate !== null) {
                  dataIn = dataIn + moment(f.execDate).format("DD/MM/YYYY HH:mm") + " ";
                }
              });
              value = dataIn;
            } else {
              value = null;
            }
            break;

          default:
            value = value[f];
        }
      });
      if (value == null) return null;
      if (col.value !== "dataIn") {
        let formatter = col.columnType === "Date" ? "formatDateTimeFromDate" : null;

        formatter = col.formatValue || formatter;

        if (formatter) {
          const formatterF = this.$options.filters[formatter];
          if (formatterF != null) value = formatterF(value);
          else console.warn("Filtro non presente: ", formatter);
        }
        if (typeof value == "object" && value.label) return value.label;
      }

      return value;
    },
    isNumeric(value) {
      return !isNaN(parseFloat(value)) && isFinite(value);
    },
  },
  components: {
    TosEditFormVuetify,
    TosViewItemVuetify,
  },
};
